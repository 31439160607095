//Home.js
import React, {useEffect, useState} from 'react'
import $ from 'jquery';
import Navbar from './Navbar'
import {Link, useParams} from "react-router-dom";
import './App.css';
import './Scripts.js';
import * as scripts from "./Scripts";
import Logo from './components/Logo'
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PurpleLoader from './components/PurpleLoader';
import ConnectionError from "./components/ConnectionError";
import Popup from "reactjs-popup";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import styled from "styled-components";
import { Copy} from '@styled-icons/feather/Copy'
import { Database} from '@styled-icons/feather/Database'
import PageLoading from "./components/PageLoading";



const ico_height = '16px';

const data_ico_height = '42px';

const CopyIco = styled(Copy)`
      height:${ico_height}
    `;

const DataIco = styled(Database)`
      height: ${data_ico_height}
    `;


const Reporting = () => {


    const get_err_msg = function(){
        return "AI is busy at the moment. please try again in a minute.";
    }
    const { project_id } = useParams();



    const [active_xhr, SetActiveXhr] = useState(false);

    const [set_project_id, SetProjectId] = useState(project_id ? project_id : "");


    const [preparing_download_msg, SetPreparingDlMsg] = useState("");
    const [project_title, SetProjectTitle] = useState("");


    const [is_ask_it_btn_submitting, SetIsAskAISubmitting] = useState(false);
    const [is_ask_ai_loaded, SetIsAskaiLoading] = useState(false);
    const [is_ask_ai_loaded_txt, SetIsAskaiLoadingTxt] = useState(false);


    const [ask_response, SetAskResponse] = useState(false);
    const [messages, SetMessages] = useState([]);



    useEffect(() => {

        console.log("messages_updated");
        if(messages.length<=2){
            return;
        }

    var messages_row_length = $('.ask_response_contents .message_row').length;

    var total_height = 0;
    for(var i=0;i<=messages_row_length-2;i++){

        total_height+=$('.message_row').eq(i).height();

    }



        $('.ask_response_contents').stop().animate({
            scrollTop: total_height+"px"
        }, 800);

    },[messages]);



    const navigate = useNavigate();
    scripts.on_before_load_redirect_inside(navigate);

    const [is_submitting, SetSubmitting] = useState(false);

    const [data, setData] = useState(0);


    const fetchData = async () => {

        try {
            const response = await fetch(window.api_url+'adtest/main/adtest_get_survey_data?project_id='+project_id+"&"+scripts.get_api_url_suffix()); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            const data = await response.json();


                if(data.project_title){
                    SetProjectTitle(data.project_title);
                }


            setData(data);

            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }

    }


    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        scripts.fix_page_contents_class_height();
        fetchData();
    }, []);



    const handleChange =  (event) => {
        $('#errors_div').html("");
    }

    function downloadBlob(content, filename, contentType) {
        // Create a blob
        var blob = new Blob([content], { type: contentType });
        var url = URL.createObjectURL(blob);

        // Create a link to download it
        var pom = document.createElement('a');
        pom.href = url;
        pom.setAttribute('download', filename);
        pom.click();
    }


    function do_export_project_csv(action,count) {

        if(count==1) {
            SetSubmitting(true);
            SetPreparingDlMsg("Preparing your download, when completed - it will appear in your downloads folder.");

            $('.download_file_popup_msg').click();

        }
        var ajax = $.ajax({
            url: window.api_url+'adtest/main/export_surveys?action='+action+'_download&selected_survey='+project_id+"&"+scripts.get_api_url_suffix(),
            type: 'get',
            contentType: false,
            processData: false,
            success: function(response){


                if(!response && count<3 ){
                    SetPreparingDlMsg("it seems to be taking longer than expected, we are still preparing your download,  when completed - it will appear in your downloads folder.");

                    return do_export_project_csv(action,(count+1));
                } else {



                    SetSubmitting(false);




                    if(response=="error" || !response){

                        $('.update_setup_form .centered').text("sorry, AI is busy. please try again later.");
                        SetPreparingDlMsg("Sorry, AI is busy. please try again later.");



                        return;
                    }

                    $('.inmodal .close').click();
                    downloadBlob(response, action+'_'+project_id+'.csv', 'text/csv;charset=utf-8;')

                }



            },
            error: function(XMLHttpRequest, textStatus, errorThrown) {

                SetSubmitting(false);
                $('.update_setup_form .centered').text("sorry, AI is busy. please try again later.");
                SetPreparingDlMsg("sorry, AI is busy. please try again later.");


            },
            timeout:60000
        });


        SetActiveXhr(ajax);


    }

    function abort_active_xhr() {


        try {

            if(active_xhr)
                active_xhr.abort();
        }
        catch(err) {

        }
        SetIsAskaiLoading(false);


    }

    function calculate_ajax_summary(count) {

        var ajax = $.ajax({
            url: window.api_url+'adtest/main/export_surveys?action=summary&selected_survey='+project_id+"&"+scripts.get_api_url_suffix(),
            type: 'get',
            contentType: false,
            processData: false,
            success: function(response){



                try {


                    var data = $.parseJSON(response);




                    SetSubmitting(false);



                    if(data.error){

                        SetIsAskaiLoading(get_err_msg());

                    }

                    if(data.response){

                        SetIsAskaiLoading(data.response);
                        SetIsAskaiLoadingTxt(data.response_text)

                    }

                }
                catch(err){

                    SetSubmitting(false);


                }



            },
            error: function(XMLHttpRequest, textStatus, errorThrown) {


                SetSubmitting(false);


                SetIsAskaiLoading(get_err_msg());

            },
            timeout:60000
        });


        SetActiveXhr(ajax);


    }

    return (
        <div>

            <Popup
                onClose={(e) => {

                    abort_active_xhr();


                }}

                trigger={
                    <div className="download_file_popup_msg">

                    </div>

                }
                modal
                nested
            >
                {close => (
                    <div className="inmodal">
                        <button className="close" onClick={close}>
                            &times;
                        </button>
                        <div className="header">Preparing Download</div>
                        <div className="content">


                            <div className="update_setup_form">

<div className="loader_div centered">
                                        <PurpleLoader height={40} />                                  {preparing_download_msg}
                                    </div>





                            </div>
                        </div>
                    </div>
                )}
            </Popup>

            <div className="allpagewrapper">
                <div className="leftbar">
                    <Logo />

                </div>

                <div className="pagecontents">
                    <h1>{project_title ? "Reporting - "+project_title : ""}</h1>


                    {loading ? (
                        <PageLoading />
                    ) : error ? (
                            <ConnectionError />
                        ) :
                        <div>
                            <div className="ask_ai form">

                                <h1><img src="/ai-ico-prpl.png" alt=""/> Targeting Results <span className="beta">(Beta)</span></h1>
                                <br/>

                                <div className="explain_ai_text">
                                    Unlock the power of AI-driven analysis to thoroughly evaluate your test results. Our advanced AI will identify the most relevant demographic and behavioral targeting strategies, specifically tailored to your digital and social media advertising needs. <br /><br />

                                    You’ll receive a detailed Summary, along with five personalized targeting recommendations, to launch highly effective campaigns on Google Search, Display, YouTube, as well as Meta (Facebook & Instagram) through Video, Stories, Reels, and Carousel Ads.<br /><br />

                                    This in-depth analysis will optimize your marketing strategies, ensuring precise audience targeting, driving higher engagement, and significantly boosting your campaign performance.
                                </div>

                                <div className="explain_btns_down">
                                    <div className="explain_btns_down_in">
                                    <Popup
                                        onClose={(e) => {

                                            abort_active_xhr();


                                        }}

                                        onOpen={() => {

                                            calculate_ajax_summary(1);


                                        }}

                                        trigger={
                                            <span className="btn_purple">
                            Summary
                            </span>


                                        }
                                        modal
                                        nested
                                    >
                                        {close => (
                                            <div className="inmodal">
                                                <button className="close" onClick={close}>
                                                    &times;
                                                </button>
                                                <div className="header">Summary</div>
                                                <div className="content">

                                                    {!is_ask_ai_loaded ? <div className="loader_div centered">
                                                            <PurpleLoader height={40} /> Calcuating your summary, please wait..
                                                        </div> :


                                                        <div>


                                                            <div className="ask_response ask_response_contents">


                                                                <div

                                                                    dangerouslySetInnerHTML={{__html: is_ask_ai_loaded}}
                                                                >

                                                                </div>
                                                                {is_ask_ai_loaded!==get_err_msg() ?
                                                                    <div className="copy_txt_div">

                                                                        <CopyToClipboard
                                                                            className="copy_clipper"
                                                                            text={is_ask_ai_loaded_txt}

                                                                            onCopy={() => {



                                                                                $('.copy_in_txt').text("✓ Copied");

                                                                                setTimeout(function(){
                                                                                    $('.copy_in_txt').text("Copy Answer Text");

                                                                                },3000);

                                                                            }}

                                                                        >

                                                                            <span className="copy_ico"><CopyIco /> <span className="copy_in_txt">Copy Answer Text</span></span>
                                                                        </CopyToClipboard>

                                                                        <div id="under_copy_warning">
                                                                            * Warning: AI results should always be checked before use.
                                                                        </div>
                                                                    </div>



                                                                    : ""}

                                                            </div>




                                                        </div>

                                                    }

                                                </div>
                                            </div>
                                        )}
                                    </Popup>

                                    <span
                                        onClick={(e) => {


                                            do_export_project_csv('meta',1);

                                        }}
                                        className="btn_purple meta_btn">
                            Download Meta
                            </span>

                                    <span
                                        onClick={(e) => {


                                            do_export_project_csv('google',1);

                                        }}

                                        className="btn_purple google_btn">
                            Download Google
                            </span>
                                    </div>
                                </div>
                                <div className="clear"></div>
                            </div>

                            <div className="form ask_ai">
                        <form method="post" action={window.api_url+"adtest/main/export_surveys?action=results_csv_download&selected_survey="+project_id+"&"+scripts.get_api_url_suffix()}>

                            <h1><DataIco /> Export Data</h1>
                            <br/>


                            <input type="hidden" name="selected_survey" value={project_id} />






                            <div className="submitor export_res">


                                <div className="export_results_explain">
                                    The results export has been designed to allow for quick and efficient download, whether you're in the early stages or nearing project 	completion, you can download your data quickly and efficiently, without missing a beat.<br /><br />
                                    Our flexible approach ensures you can analyze your results without delays, utilizing advanced AI tools like ChatGPT and Google Gemini, traditional platforms like Excel and Google Sheets, or specialized software such as SPSS, R, and beyond.
                                    With this versatile design, you have the freedom to choose the analytical tool that perfectly fits your needs, making the process of extracting insights from your survey data both smooth and adaptable to any workflow.
                                </div>

                                <input className="btn_purple export" disabled={is_submitting} type="submit" value="Export" />
<div className="clear"></div>



                            </div>







                        </form>



                    </div>


                            <div id="more_spacing"></div>

                        </div>
                    }

                </div>
            </div>
        </div>

    )
}

export default Reporting;